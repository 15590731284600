import React from 'react'
import * as stylesMenuDec from '@styles/menu-sidebar/menu.module.css'
import { graphql, Link, useStaticQuery } from 'gatsby'

export default function MenuEasySignIntroduction() {
  const data = useStaticQuery(graphql`
    query SiteInfo{
      site{
        siteMetadata {
          menuEasySign{
            title
            fontSize
            subtitle{
              textBold
              name
              link
            }
          }
        }
      }
    }
  `)
  const menuEasySign = data.site.siteMetadata.menuEasySign
  return (
    <div className={stylesMenuDec.container}>
      <img
        src="/media/images/easy-sign/logo_easy_sign.svg"
        alt="EasySign"
        className={stylesMenuDec.easySign}
      />
      <hr className={stylesMenuDec.separator} />

      <div className={stylesMenuDec.main}>
        {menuEasySign.map(menu => (
          <div key={menu.title}>
            <span className={stylesMenuDec.titlesMain}  style={{ fontSize:menu.fontSize }}>{menu.title}</span>
            {menu.subtitle.map(subtitle => (
              <Link key={subtitle.name} to={subtitle.link} className={stylesMenuDec.link} activeClassName={stylesMenuDec.activeBorderLeft}>
                <p className={stylesMenuDec.titles}><span className={stylesMenuDec.titlesBold}>{subtitle.textBold}</span>{subtitle.name}</p>
              </Link>
            ))}
          </div>
        ))}
      </div>

      <hr className={stylesMenuDec.separator} />
      <Link to="/" className={stylesMenuDec.buttonMenu}>
        <p className={stylesMenuDec.titlesButtonMenu}>Inicio</p>
      </Link>
      <a
        href={process.env.URL_CONTACTS}
        target="_blank"
        rel="noreferrer"
        className={stylesMenuDec.buttonMenu}
      >
        <p className={stylesMenuDec.titlesButtonMenu}>Contáctanos</p>
      </a>
    </div>
  )
} 


